html {
  font-size: 10px;
  font-family: 'Roboto', sans-serif;
}

a,
a:visited,
a:hover {
  text-decoration: none;
  color: inherit;
}
