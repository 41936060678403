html {
  font-family: Roboto, sans-serif;
  font-size: 10px;
}

a, a:visited, a:hover {
  color: inherit;
  text-decoration: none;
}
/*# sourceMappingURL=index.d2afe80b.css.map */
